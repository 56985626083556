.admin-login__title {
    margin-top: 60px;
}

@media (max-height: 700px) {
    .admin-form-container {
        margin-top: 50px;
        width: 580px;
        height: 400px;
    }
}
