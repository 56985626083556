.review-header {
    height: 72px;
    width: 100vw;
}

.review-header__container {
    display: flex;
    margin: 0 5vw;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.review-header__nav-search {
    height: 28px;
    width: 18vw;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding-left: 40px;
}

.review-header__nav-search::placeholder {
    color: #D9D9D9;
}


.review-header__nav-search {
    outline: none;
}

.review-header__nav {
    display: flex;
    align-items: center;
    position: relative;
}

.review-header__nav-searchIcon {
    width: 20px;
    position: absolute;
    left: 13px;
}

.review-header__nav-date {
    height: 30px;
    padding-left: 20px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    outline: none;
}

.review-header__nav2-mail {
    width: 36px;
    height: 36px;
    background-color: #D9D9D9;
    border-radius: 50%;
    position: relative;
}

.review-header__nav2-mailIcon {
    width: 24px;
    position: absolute;
    top: 5px;
    left: 8px;
}

.review-header__nav2-user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.review-header__nav2-userIcon {
    width: 40px;
}

@media (min-width: 1180px) {
    .header-container__adminNav {
        margin-left: 200px;
    }
}
