.view-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

.view-profile__avatar {
    width: 150px;
    height: 150px;
    border-radius: 75px;
}

.view-profile__container {
    text-align: center;
    margin-top: 20px;
}

.view-profile__campaigns {
    margin-top: 20px;
}

.view-profile__campaign-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.view-profile__campaign-photo {
    width: 200px;
    border-radius: 5px;
    margin-right: 10px;
}

.view-profile__campaign-details {
    flex-grow: 1;
}

.error {
    color: red;
    text-align: center;
}

.view-profile__business-account {
    color: green;
}
