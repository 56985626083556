@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider-box {
    margin: 20px 0;
    display: flex !important;
    width: 100%;
}

.slider-box__img-box {
    width: 50%;
    border: 2px solid #D9D9D9;
    border-right: none;
    border-radius: 12px 0 0 12px;
}

.slider-box__img-box-img {
    border-radius: 10px 0 0 10px;

}

.slider-box__description-box {
    border-radius: 0 10px 10px 0;
    border: 2px solid #D9D9D9;
    border-left: none;
    width: 50%;
    position: relative;
}

.slider-box__description-box-title {
    padding: 30px 30px 0;
}

.slider-box__description-box-description {
    padding: 0 30px 0;
}

.slider-box__img-box img {
    width: 100%;
    height: auto;
}

.thumbnail-slider-box p {
    font-family: Noto Sans, serif;
    font-size: 16px;
}

.thumbnail-slider-wrap {
    display: flex !important;
    gap: 10px !important;
}

.thumbnail-slider-box__item p {
    margin-top: 5px;
    padding: 0 6px;
}
