.campaigns {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.campaigns-item {
    border-radius: 10px;
    border: 1px solid #D9D9D9;
}

.campaigns-item__trend {
    border-bottom: 1px solid #D9D9D9;
    padding: 10px;
}

.campaigns-item__trend img {
    margin-right: 10px;
}

.campaigns-item__content {
    display: flex;
    padding: 10px;
    gap: 20px;
}

.campaigns-item__content-text {
    display: flex;
    flex-direction: column;
    flex: 6;
}

.campaigns-item__content-text h3 {
    margin: 0;
}

.campaigns-item__content-text p {
    margin: 10px 0;
}

.campaigns-item__content-user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.campaigns-item__content-userAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.campaigns-item__content-business {
    display: flex;
    align-items: center;
}

.campaigns-item__content-businessIcon {
    width: 20px;
}

.campaigns-item__enclosure-img {
    display: flex;
    flex: 4;
}

.campaigns-item__content-img img {
    width: 100%;
    height: auto;
    max-height: 200px;
}

@media (max-width: 1200px) {
    .campaigns-item__content-img {
        flex: 4.5;
    }
}

@media (max-width: 900px) {
    .campaigns-item__content-img {
        flex: 4;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-height: 80%;
    overflow-y: auto;
}
