.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main {
    max-width: 1280px;
    margin: 0 auto;
    width: 90vw;
    height: 100%;
    padding: 0 5vw;
}

.main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.main-posts {
    display: flex;
    gap: 20px;
}

.main-posts-campaigns {
    width: 70%;
}

.main-posts-companies {
    width: 30%;
    border: 1px solid #eeeded;
    padding-left: 10px;
}

.content-full {
    display: block;
}

.read-more {
    text-decoration:underline;
    color: blue;
}

.read-more:hover {
    cursor: pointer;
}

.slider-box-profile {
    display: flex;
    border: 1px solid #E5E5E5;
    border-bottom: none;
    border-right: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 50px;
}

.slider-box-profile__user {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;
    border-right: 1px solid #E5E5E5;
    padding-left: 10px;
}

.slider-box-profile__participant {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;
    padding-left: 10px;
}

.slider-box-profile__participant-number {
    display: flex;
    flex-direction: column;
}

.slider-box-profile__participant-number :first-child {
    font-family: Outfit, serif;
}

@media (max-width: 500px) {
    .carousel,
    .campaigns-item__content-user,
    .main-posts-companies,
    .header-container__nav {
        display: none;
    }
    .main-posts-campaigns {
        width: 100%;
    }
    .campaigns-item__content {
        display: flex;
        flex-direction: column;
    }
}
