.review-content {
    height: 80vh;
    margin-top: 40px;
    display: flex;
}

.review-content__sideBar {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 25%;
    margin-top: 20px;
}

.review-content__sideBar-item {
    height: 60px;
    font-size: 30px;
    font-weight: 600;
    color: #74c3d5;
    padding-left: 20px;
    position: relative;
}

.review-content__sideBar-item::before {
    content: '';
    display: block;
    width: 8px;
    height: 75%;
    background-color: transparent;
    position: absolute;
    left: -40px;
    transition: background-color 0.3s;
}

.review-content__sideBar-item-active::before {
    background-color: #2F879B;
}

.review-content__sideBar li:hover {
    cursor: pointer;
}

.review-content__main {
    background-color: #F3F1F1;
    width: 85%;
    padding: 40px;
    display: flex;
    gap: 40px;
}

.review-content__main-campaign {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #00000040;
    background-color: white;
    padding: 10px;
    overflow-x: auto;
    overflow-y: auto;
}

@media (max-width: 500px) {
    .review-content__main {
        width: 100vw;
        padding: 0;
    }
    .review-content__sideBar,
    .header-container__nav,
    .header-container__adminNav,
    .header-container__operations,
    .campaign-table th:nth-child(4),
    .campaign-table td:nth-child(4) {
        display: none;
    }
}
