.form-container {
    margin-top: 20px;
    width: 628px;
    height: 500px;
    border: 1px solid #a4a3a3;
    border-radius: 20px;
    background-color: #F3F1F1;
    box-shadow:5px 5px 5px 0 rgba(0,0,0,0.75);
}

.form-container h1 {
    display: flex;
    justify-content: center;
}

.form {
    padding: 10px 80px;
}

.form fieldset {
    border: none;
    padding: 0;
}

.form-input {
    position: relative;
}

.form-input-img {
    box-sizing: border-box;
    position: absolute;
    left: -2px;
    top: 13px;
    width: 38px;
    height: 24px;
    border-radius: 10px;
    z-index: 99;
}

.from-input-eye {
    position: absolute;
    right: -36px;
    bottom: 3px;
}

.from-input-eye:hover {
    cursor: pointer;
}

.form-input input {
    margin-top: 10px;
    width: 100%;
    border: 1px solid #acaeb0;
    border-radius: 5px;
    padding: 5px 0 5px 40px;
    outline: none;
    font-size: 16px;
    font-family: Inter, serif;
}

.form-to-middle {
    display: flex;
    justify-content: center;
}

.error-message {
    color: red;
}

.form-button {
    width: 80%;
    padding: 8px;
    background-color: #1C859E;
    border: 1px solid #1C859E;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 700;
}

.form-business-selection {
    display: flex;
    align-items: center;
    gap: 100px;
}

.form-business-selection-icon {
    margin: 0 2px -3px 5px;
}

@media (min-height: 700px) {
    .form-container {
        margin-top: 100px;
    }
}

@media (max-width: 500px) {
    .login {
        display: flex;
        justify-content: center;
        padding: 0 20px; /* Add padding for better spacing */
        width: 90vw;
    }
    .form-container {
        height: auto;
        padding: 0 40px 40px 10px;
    }
    .form {
        padding: 10px;
    }
    .form-button {
        width: 100%;
    }
    .form-to-middle {
        width: 110%;
    }
}
