.header-menu-items {
    display: flex;
    flex-direction: column;
    gap: 40px;
    list-style-type: none;
    margin-top: 30px;
    padding-left: 20px;
}

.header-menu-cancelBox {
    display: none;
}

@media (max-width: 500px) {
    .header-menu-cancelBox {
        display: flex;
        justify-content: end;
        margin-top: 10px;
        width: 48vw;
    }
}

.header-menu-cancelIcon {
    display: none;
}

@media (max-width: 500px) {
    .header-menu-cancelIcon {
        display: flex;
        width: 30px;
        padding: 6px;
        background-color: #cecdcd;
        border-radius: 50%;
    }
}
