.App {
    display: flex;
    justify-content: center;
    max-width: 1280px;
}

.link-no-style {
    text-decoration: none;
    color: inherit;
}

.link-style {
    text-decoration: none;
    font-weight: 700;
    color: #6C70C4;
}
