.header {
    height: 72px;
    width: 100vw;
    border-bottom: 1px solid black;
    position: relative;
}

@media (max-width: 500px) {
    .header {
        height: 60px;
    }
}

.header-menu {
    display: none;
}

@media (max-width: 500px) {
    .header-menu {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 50vw;
        height: 50vh;
        background-color: white;
        z-index: 999;
    }
}

.header-menu-close {
}

@media (max-width: 500px) {
    .header-menu-close {
        display: none;
    }
}

.header-container {
    max-width: 1280px;
    margin: 0 auto;
    width: 90vw;
    height: 100%;
    padding: 0 5vw;
    display: flex;
    justify-content: space-between;
}

.header-container__logo-nav {
    display: flex;
}

@media (max-width: 500px) {
    .header-container__logo-nav {
        width: 100%;
        justify-content: space-between;
    }
}

.header-container__logo {
    display: flex;
    gap: 10px;
    align-items: center;
}

.header-container__logoIcon {
    height: 72px;
}

@media (max-width: 500px) {
    .header-container__logoIcon {
        height: 60px;
    }
}

.header-container__logoIcon, .header-container__title:hover {
    cursor: pointer;
}

.header-container__logo span {
    font-family: Inter, serif;
    font-weight: 700;
    font-size: 24px;
    color: #2F879B;
}

@media (max-width: 500px) {
    .header-container__logo span {
        font-size: 20px;
    }
}

.header-container__nav {
    display: flex;
    align-items: center;
    gap: 30px;
    list-style-type: none;
    font-weight: 700;
    font-family: Inter, serif;
}

.header-container__nav li {
    transition: color 0.3s ease;
    cursor: pointer;
}

.header-container__nav li:hover {
    color: #2F879B;
}

.header-container__nav2 {
    display: flex;
    align-items: center;
    gap: 30px;
    list-style-type: none;
    font-weight: 700;
}

.header-container__nav2 span, .header-container__nav2-post {
    transition: color 0.3s ease;
    cursor: pointer;
    font-family: Inter, serif;
}

.header-container__nav2 span:hover, .header-container__nav2-post:hover {
    color: #2F879B;
}

.header-container__operations {
    display: flex;
}

.header-container__nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-family: Inter, serif;
    font-weight: 700;
    font-size: 16px;
    color: black;
}

.header-container__nav-user {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header-container__icon {
    display: none;
}

@media (max-width: 500px) {
    .header-container__icon {
        display: flex;
        width: 40px;
    }
}

@media (min-width: 1280px) {
    .header-container {
        width: 1280px;
        padding: 0;
    }
}
